import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import visa from './../../assets/images/visa-logo.png'
import master from './../../assets/images/master-logo.png'
import maestro from './../../assets/images/maestro.png'
import paypal from './../../assets/images/paypal.png'
import american from './../../assets/images/american.png'
import BgHero from './../../assets/images/bg-hero-new-moh.jpg';
import HomeBg from './../../assets/images/home-bg-2.jpg';
import 'remixicon/fonts/remixicon.css';
import HomeActions from '../../redux/home/action';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import Styles from './HomePage.module.css';
import NoImage from '../../assets/images/no_image.png';
import UtilitySvg from '../../assets/images/utilitysvg.png';
import ReadMore from '../../assets/images/right.png'
import RechargeActions from '../../redux/recharge/actions'

const { getCountriesWhoHasNetwork, getNetworksByCountryID, setFormData, getServices } = HomeActions;
const { selectedNetwork } = RechargeActions;

const BASE_URL = process.env.REACT_APP_BASEURL;

const HomePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const { getNetworksByCountryIdList, serviceList } = useSelector((state) => state.home);

    const slides = [
        {
            img: BgHero, text: <div>
                <h1>Welcome to <span style={{ color: "#0cc6e6" }}>Moh</span> Connect</h1>
                <p>At MohConnect, we cover distances between you and your loved ones. Based in the heart of New York City, our aim is to make global connections much easier and more accessible. </p>
            </div>
        },
        {
            img: HomeBg, text: <div>
                <h1>Welcome to <span style={{ color: "#0cc6e6" }}>Moh</span> Connect</h1>
                <p>At MohConnect, we cover distances between you and your loved ones. Based in the heart of New York City, our aim is to make global connections much easier and more accessible. </p>
            </div>
        },
        {
            img: BgHero, text: <div>
                <h1>Welcome to <span style={{ color: "#0cc6e6" }}>Moh</span> Connect</h1>
                <p>At MohConnect, we cover distances between you and your loved ones. Based in the heart of New York City, our aim is to make global connections much easier and more accessible. </p>
            </div>
        },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === slides.length - 1 ? 0 : prevIndex + 1
            );
        }, 4000); // Change slide every 4 seconds

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [slides.length]);

    /*
    useEffect(() => {
        dispatch(getCountriesWhoHasNetwork());
        dispatch(getNetworksByCountryID(233));
        dispatch(setFormData('countryId', 233));
        dispatch(getServices(233));
    }, []);
    */


    // POPUP modal
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsOpen(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    const handleWindowClick = (e) => {
        setIsOpen(false);
    };

    useEffect(() => {
        window.addEventListener('click', handleWindowClick);
        return () => window.removeEventListener('click', handleWindowClick);
    }, []);
    // POPUP modal

    const handleReadMore = () => {
        navigate('/recharge');
    };

    const handleNetworkChange = (e) => {
        const networkId = e.target.value;
        dispatch(selectedNetwork(networkId));
        navigate('/recharge');
    }

    const handleServiceChange = (e) => {
        console.log("serviceId", e.target.value)
    }

    return (
        <>
            <section className="bg-white pt-[3rem] sec-bg-hero-1">
                <div className="relative w-full  mx-auto overflow-hidden">
                    <div className="flex transition-transform duration-1000 ease-in-out" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                        {slides.map((slide, index) => (
                            <div key={index} className="relative w-full flex-shrink-0">
                                <img src={slide.img} alt={`Slide ${index + 1}`} className="w-full object-cover" />
                                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-[0.03] text-white p-4">
                                    <div className='herosec-text'>
                                        {slide.text}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>


            <section className=' pt-[2rem] pb-[2rem]'>
                <div className='container mx-auto '>
                    <div className='grid grid-cols-3  w-[82%] gap-2 mx-auto'>

                        {getNetworksByCountryIdList && getNetworksByCountryIdList.length > 0 ? <div class=" max-w-sm bg-white border border-gray-200 rounded-lg shadow bg-card-second-home">
                            <div className='overlay p-6 '>
                                <div className='flex gap-2'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="34" viewBox="0 0 27 34" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.4996 0.515L8.49965 0.5C6.84965 0.5 5.51465 1.85 5.51465 3.5V9.00502L8.49965 6.5003V6.5H8.5H23.4996V27.5H8.49965V22.3999L5.51465 19.9086V30.5C5.51465 32.15 6.84965 33.5 8.49965 33.5H23.4996C25.1496 33.5 26.4996 32.15 26.4996 30.5V3.5C26.4996 1.85 25.1496 0.515 23.4996 0.515ZM8 9L0 14.5L8 20.5V17C8 17 12 17 14 19C15.1848 19.9478 15.9204 22.8048 16.3665 24.5372C16.8616 26.4603 17 26.9978 17 22C17 12.5 8 12.5 8 12.5V9Z" fill="#565F82" />
                                        </svg>
                                    </div>
                                    <div>
                                        <a href="#">
                                            <h5 class="mb-2 text-2xl font-normal tracking-tight text-gray-900">Recharge</h5>
                                        </a>
                                    </div>
                                </div>
                                <div className='py-[15px]'>
                                    <div className='grid grid-cols-3 gap-2'>
                                        {getNetworksByCountryIdList && getNetworksByCountryIdList.slice(0, 5).map((item) => (
                                            <div>
                                                <label className="custom-radio" key={`network${item.ID}`} title={item.NetworkName}>
                                                    <input type="radio" name="networkId" value={item.ID} onChange={(e) => handleNetworkChange(e)} />
                                                    <span className="radio-btn-card">
                                                        <div className="hobbies-icon">
                                                            <img src={item.NetworkLogoURL ? `${BASE_URL}/images/network/${item.NetworkLogoURL}` : NoImage} alt={item.NetworkName} />
                                                        </div>
                                                    </span>
                                                </label>
                                            </div>

                                        ))}
                                        {getNetworksByCountryIdList.length > 5 && (
                                            <button onClick={handleReadMore} className="text-blue-500 hover:underline mt-0 read-more-one" >
                                                <img src={ReadMore} alt="" width={27} />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div> : ''}

                        {serviceList && serviceList.length > 0 ? <div class=" max-w-sm bg-white border border-gray-200 rounded-lg shadow bg-card-second-home">
                            <div className='overlay p-6 '>
                                <div className='flex gap-2'>
                                    <div>
                                        <img className="" src={UtilitySvg} alt="airbnb" width="36" height="36" />
                                    </div>
                                    <div>
                                        <a href="#">
                                            <h5 class="mb-2 text-2xl font-normal tracking-tight text-gray-900">Utility</h5>
                                        </a>
                                    </div>
                                </div>
                                <div className='py-[15px]'>
                                    <div className='grid grid-cols-3 gap-2'>
                                        {serviceList && serviceList.slice(0, 5).map((item, index) => (
                                            <div key={`utilities${index}`}>
                                                <label className="custom-radio" key={item.ID} title={item.UtilityName}>
                                                    <input type="radio" name="networkId" value={item.ID} onChange={(e) => handleServiceChange(e)} />
                                                    <div className="radio-btn-card">
                                                        <div className="hobbies-icon">
                                                            <Link to={`${item.PageURL}/${item.ID}`}>
                                                                <img src={item.UtilityLogoURL ? `${BASE_URL}/images/utilities/${item.UtilityLogoURL}` : NoImage} alt={item.UtilityName} />

                                                                {/* <img src={item.UtilityLogoURL ? `${BASE_URL}/images/${item.UtilityLogoURL}` : NoImage} alt={item.UtilityName} /> 
                                                                {/* {item.UtilityLogoURL ?? ''} 
                                                                <div
                                                                    dangerouslySetInnerHTML={{ __html: item.UtilityLogoURL ?? '' }}
                                                                />*/}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>

                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div> : ''}
                    </div>
                </div>
            </section>



            <section className='from-[#0f172a] to-[#0f172a] solutions'  >
                <div className="mx-auto px-4 sm:px-12 xl:max-w-6xl xl:px-0">
                    <div className="border-y border-gray-100 py-11 text-center md:py-12">
                        <p className="text-xl font-bold tracking-wider text-gray-800">Supported payment methods</p>
                        <div className="mt-8 grid grid-cols-5 gap-[1rem]">

                            <img className="" src={visa} loading="lazy" alt="airbnb" width="300" height="300" />

                            <img className="" src={master} loading="lazy" alt="bissell" width="300" height="300" />

                            <img className="" src={paypal} loading="lazy" alt="ge" width="300" height="300" />

                            <img className="" src={maestro} loading="lazy" alt="lilly" width="300" height="300" />

                            <img className="" src={american} loading="lazy" alt="microsoft" width="300" height="300" />

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomePage