import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import rechargeSvg from './../../assets/images/cloud-bg.png';
import Button from '../../components/utils/Button';
import { isAuthenticated } from '../../helper/commonFunction';
import HomeActions from '../../redux/home/action';
import Loader from '../../components/utils/Loader';
import UtilityActions from '../../redux/utility/action'
import LoginModal from '../../components/utils/LoginModal';
import { makeUtilityPayment } from '../../helper/stripe';
import Alert from '../../components/utils/Alert';
import NoImage from '../../assets/images/no_image.png';

const { verifyUtilityID } = HomeActions;
const { getUtilityCommission, formReset, searchForUtilityDetails, createUtilityOrder, openLoginModal } = UtilityActions;

const checkField = (val) => (val != '' && val != null && val != undefined);

const BASE_URL = process.env.REACT_APP_BASEURL;

const Biller = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { searchUtilityDetailsData, searchUtilityDetailsLoading, searchUtilityDetailsError, getUtilityCommissionList, createUtilityOrderLoading, createUtilityOrderData, showLoginModal } = useSelector((state) => state.utility);
    const { getNetworkByCountryIDMessage, getNetworkByCountryIDError, getNetworkByCountryIDLoading, UtilitylogoUrl } = useSelector((state) => state.home);
    const [serviceNumber, setServiceNumber] = useState('');
    const [email, setEmail] = useState('');
    const [showSearchButton, setShowSearchButton] = useState(false);
    const [totalAmount, setTotalAmount] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');

    useEffect(() => {
        dispatch(verifyUtilityID(id))
        dispatch(getUtilityCommission(id))
    }, [id])

    useEffect(() => {
        if (getNetworkByCountryIDError) {
            window.location.href = `/`;
        }
    }, [getNetworkByCountryIDMessage, getNetworkByCountryIDError])

    useEffect(() => {
        if (searchUtilityDetailsData && searchUtilityDetailsData.length > 0) {
            let netAmount = searchUtilityDetailsData[0].total
            setTotalAmount(netAmount)
        }
    }, [searchUtilityDetailsData])

    useEffect(() => {
        if (createUtilityOrderData.ID && createUtilityOrderData.OrderNumber) {
            localStorage.setItem('payment', 1);
            const products = [
                {
                    name: createUtilityOrderData.UtilityName,
                    price: createUtilityOrderData.AmountPaid,
                    // image: `${BASE_URL}/images/network/${createUtilityOrderData.NetworkLogoURL}`,
                    quantity: 1
                }
            ]
            makeUtilityPayment(products, createUtilityOrderData.PaymentTxnID, id);
        }
    }, [createUtilityOrderData]);

    useEffect(() => {
        if (searchUtilityDetailsError !== null && searchUtilityDetailsError !== undefined) {
            displayAlert(searchUtilityDetailsError, 'alert-danger');
        }
    }, [searchUtilityDetailsError]);

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(formReset());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(formReset());
    }

    const allFieldValid = useMemo(() => {
        if (checkField(serviceNumber) && checkField(totalAmount) && checkField(mobileNumber)) {
            return true;
        } else {
            return false;
        }
    }, [serviceNumber, totalAmount, mobileNumber]);

    const handleChangeServiceNumber = (e) => {
        let value = e.target.value
        if (value) {
            setShowSearchButton(true)
            setServiceNumber(value)
        }
        else {
            setShowSearchButton(false)
            dispatch(formReset())
            setEmail('')
            checkField('')
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        const data = {
            "consumerNumber": serviceNumber,
            "endPoint": "wizall/senelec/searchBill"
        }
        if (data.serviceNumber != '' || data.serviceNumber != 'undefined' || data.serviceNumber != null) {
            if (isAuthenticated()) {
                dispatch(searchForUtilityDetails(id, data));
            } else {
                dispatch(openLoginModal())
                // window.location.href = `/login`;
            }
        }
    };

    const handleMobileNumberChange = (e) => {
        let value = e.target.value;

        if (value.length <= 10) {
            setMobileNumber(value);
        }
    }

    const handleEmailChange = (e) => {
        const email = e.target.value;
        const pattern = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
        const isValidEmail = pattern.test(email);
        if (isValidEmail) {
            setEmail(email)
        } else {
            setEmail('')
        }
    };

    const onSubmit = (e) => {
        e.preventDefault()
        const newData = {
            ConsumerNumber: serviceNumber,
            SenderMobile: mobileNumber,
            InvoiceNumber: searchUtilityDetailsData[0].numfacture,
            UtilityID: id,
            FeePaid: searchUtilityDetailsData[0].frais,
            AmountPaid: totalAmount,
        }
        dispatch(createUtilityOrder(id, newData))
    }


    return (
        <>
            <div className='maindiv-elrctric'>
                {getNetworkByCountryIDLoading ? <Loader /> : <div className='postpaid'>
                    <section className="wrapper !bg-[#8ca0e12e]">
                        <div className="container mx-auto">
                            <div className='py-14' >
                                <h1 className="pt-32 xl:text-[2.5rem] text-[calc(1.375rem_+_1.5vw)] !leading-[1.15] font-bold  xl:!mr-5 xl:mt-[-2.5rem] lg:mt-[-2.5rem] text-center" data-cue="slideInDown" data-group="page-title" data-delay="900" data-show="true" >
                                    Biller <br className="hidden md:block xl:hidden lg:hidden" /> <span className="text-[#1d89ca] "></span>
                                </h1>
                            </div>
                        </div>
                        <figure className="m-0 p-0"><img className="w-full max-w-full !h-auto" src={rechargeSvg} alt="image" /></figure>
                    </section>
                    <section id="snippet-2" className="wrapper !bg-[#f5f7fa] border-b-[rgba(164,174,198,0.2)]  px-32">
                        <form onSubmit={onSubmit}>
                            <div className='w-[450px] mx-auto p-2 form-sec-main'>
                                <div className="bg-white shadow-md rounded px-6 pb-4 payrechar-d">
                                    <div className='flex items-center justify-between mb-4'>
                                        <div><h2 className="text-start text-lg font-[600] mb-4">
                                            {/* Please enter the information for payment */}Enter payment details
                                        </h2></div>
                                        <div><img className='postPaidElectric' style={{ margin: '-19px 0 0px 0' }} src={UtilitylogoUrl ? `${BASE_URL}/images/utilities/${UtilitylogoUrl}` : NoImage} alt={''} width={90} height={90} /></div>
                                    </div>


                                    {showAlert && <div className='flex gap-1 items-center mb-4' style={{ color: '#d63031' }}>
                                        <i class="ri-close-circle-line "></i><span style={{ fontSize: '15px' }}>{alertText}</span>
                                    </div>}

                                    <div className="flex items-center justify-between gap-3">
                                        <div className="w-full">

                                            <div className="relative w-full input-container">
                                                <input
                                                    type="text"
                                                    placeholder=""
                                                    className="block w-full p-2 text-gray-900 border rounded bg-gray-50 text-xs focus:outline-none input-field"
                                                    onChange={(e) => handleChangeServiceNumber(e)}
                                                    maxLength={15}
                                                />
                                                <label for="input" class="label">Consumer Reference Number </label>
                                                <div class="underline"></div>
                                            </div>
                                        </div>
                                        {showSearchButton ? (
                                            <div className="flex justify-start mt-0 w-[5rem] ">
                                                <Button
                                                    loading={searchUtilityDetailsLoading}
                                                    title={''}
                                                    icon={searchUtilityDetailsLoading ? '' : < i class="ri-search-line"></i>}
                                                    buttonClass={`h-[35px] w-full pt-[5px] flex align-center justify-center relative font-medium text-white transition duration-300 rounded-md bg-[#223b8be6] hover:bg-[#223b8b] ease  cursor-pointer`}
                                                    buttonType={'button'}
                                                    onClick={handleSearch}
                                                />
                                            </div>
                                        ) : null}
                                    </div>


                                    {searchUtilityDetailsData && searchUtilityDetailsData.length > 0 && searchUtilityDetailsData.map(item => (
                                        <div className='flex flex-col gap-4 rounded-sm w-full mb-3 mt-2'>
                                            <h1 className='font-bold text-xl '>Bill Details:</h1>
                                            <div className='flex gap-6'>
                                                <div className='flex w-1/2 justify-between'> <span className='font-semibold'>Service #:</span> <span className='text-sm'>{item.police}</span> </div>
                                                <div className='flex w-1/2 justify-between'> <span className='font-semibold '>Invoice #:</span> <span className='text-sm'>{item.numfacture}</span> </div>
                                            </div>
                                            <div className='flex gap-6'>
                                                <div className='flex w-1/2 justify-between'><span className='font-semibold'>Due Date:</span> <span className='text-sm'>{item.dateecheance}</span> </div>
                                                <div className='flex w-1/2 justify-between'><span className='font-semibold'>Amount:</span> <span className='text-sm'><span className='font-semibold'>{getUtilityCommissionList && getUtilityCommissionList.length > 0 ? getUtilityCommissionList[0].CurrencyCode == '$' ? getUtilityCommissionList[0].CurrencyCode : getUtilityCommissionList[0].CurrencyCode.toUpperCase() : ''}</span> {item.montant}</span> </div>
                                            </div>
                                            <div className='flex gap-6'>
                                                <div className='flex w-1/2 justify-between'><span className='font-semibold'>Fee:</span> <span className='text-sm'><span className='font-semibold'>{getUtilityCommissionList && getUtilityCommissionList.length > 0 ? getUtilityCommissionList[0].CurrencyCode == '$' ? getUtilityCommissionList[0].CurrencyCode : getUtilityCommissionList[0].CurrencyCode.toUpperCase() : ''}</span> {item.frais}</span> </div>
                                                <div className='flex w-1/2 justify-between'><span className='font-semibold'>Total Amount:</span> <span ><span className='font-semibold'>{getUtilityCommissionList && getUtilityCommissionList.length > 0 ? getUtilityCommissionList[0].CurrencyCode == '$' ? getUtilityCommissionList[0].CurrencyCode : getUtilityCommissionList[0].CurrencyCode.toUpperCase() : ''}</span> {item.total}</span> </div>
                                            </div>
                                            <div className='flex gap-6 '>

                                            </div>
                                        </div>
                                    ))}

                                    {searchUtilityDetailsData && searchUtilityDetailsData.length > 0 ? (
                                        <div className="flex flex-col">
                                            <h2 className="text-start pb-2 xl:text-md lg:text-md font-medium text-gray-900">Amount</h2>
                                            <div className="relative w-full">
                                                <input
                                                    value={totalAmount}
                                                    type='number'
                                                    placeholder='Amount'
                                                    className="block w-full p-2 text-gray-900 border rounded bg-gray-50 text-xs focus:outline-none"
                                                    disabled
                                                />
                                            </div>
                                            <div className="flex flex-col">
                                                <h2 className="block mb-2 text-md font-medium text-gray-900">Mobile Number</h2>
                                                <div className="relative w-full">
                                                    <input
                                                        type='number'
                                                        value={mobileNumber}
                                                        placeholder='Mobile Number'
                                                        className="block w-full p-2 text-gray-900 border rounded bg-gray-50 text-xs focus:outline-none"
                                                        onChange={(e) => handleMobileNumberChange(e)}
                                                        onWheel={(e) => e.target.blur()}
                                                        onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()}
                                                    />
                                                </div>
                                            </div>
                                            {/* <h2 className="text-start pb-2 xl:text-md lg:text-md font-medium text-gray-900">Email</h2>
                                        <div className="flex flex-col">
                                            <div className="relative w-full">
                                                <input
                                                    type='email'
                                                    placeholder='Email'
                                                    className="block w-full p-2 text-gray-900 border rounded bg-gray-50 text-xs focus:outline-none"
                                                    onChange={(e) => handleEmailChange(e)}
                                                />
                                            </div>
                                        </div> */}
                                        </div>
                                    ) : null}

                                    <Button
                                        title={'Proceed'}
                                        loading={createUtilityOrderLoading}
                                        buttonClass={`mx-auto w-full mt-[20px] text-lg flex align-center justify-center relative font-medium text-white transition duration-300 rounded-md ${allFieldValid ? 'bg-[#223b8be6] hover:bg-[#223b8b]' : 'bg-[#95a5a6]'} ease btn-padd-n cursor-pointer`}
                                        buttonType={'submit'}
                                        disable={!allFieldValid}
                                    />
                                </div>
                            </div>

                        </form>



                    </section>
                    {showLoginModal ? <LoginModal /> : ''}
                </div>}
            </div>
        </>
    )
}

export default Biller