import actions from "./action";

const initState = {
    getCountriesWhoHasNetworkList: [],
    getCountriesWhoHasNetworkLoading: false,
    getCountriesWhoHasNetworkError: null,
    getNetworksByCountryIdList: [],
    getNetworksByCountryIdLoading: false,
    getNetworksByCountryIdError: null,
    formObject: { countryId: 233 },
    selectedCountryID: null,
    serviceList: [],
    serviceLoading: false,
    serviceError: null,
    serviceMessage: null,
    getNetworkByCountryIDLoading: false,
    getNetworkByCountryIDError: null,
    getNetworkByCountryIDMessage: null,
    UtilitylogoUrl: null,
}

export default function reducer(state = initState, action) {
    switch (action.type) {

        case actions.VERIFY_UTILITY_ID:
            return {
                ...state,
                getNetworkByCountryIDLoading: true,
                getNetworkByCountryIDError: null,
                getNetworkByCountryIDMessage: null,
            };
        case actions.VERIFY_UTILITY_ID_SUCCESS:
            return {
                ...state,
                getNetworkByCountryIDLoading: false,
                getNetworkByCountryIDError: null,
                getNetworkByCountryIDMessage: action.payload.message,
                UtilitylogoUrl: action.payload.utilitylogoUrl
            };
        case actions.VERIFY_UTILITY_ID_FAILED:
            return {
                ...state,
                getNetworkByCountryIDLoading: false,
                getNetworkByCountryIDError: action.payload.error,
                getNetworkByCountryIDMessage: null,
            };


        case actions.SET_FORM_DATA:
            let tempData = state.formObject;
            let newData = {
                ...tempData,
                [action.payload.key]: action.payload.value
            }
            return {
                ...state,
                formObject: newData
            };

        case actions.GET_NETWORKS_BY_COUNTRY_ID:
            return {
                ...state,
                selectedCountryID: action.payload.countryID,
                getNetworksByCountryIdList: [],
                getNetworksByCountryIdLoading: true,
                getNetworksByCountryIdError: null,
            };
        case actions.GET_NETWORKS_BY_COUNTRY_ID_SUCCESS:
            return {
                ...state,
                getNetworksByCountryIdList: action.payload.data,
                getNetworksByCountryIdLoading: false,
                getNetworksByCountryIdError: null,
            };
        case actions.GET_NETWORKS_BY_COUNTRY_ID_FAILED:
            return {
                ...state,
                getNetworksByCountryIdList: [],
                getNetworksByCountryIdLoading: false,
                getNetworksByCountryIdError: action.payload.error,
            };

        case actions.GET_COUNTRIES_WHO_HAS_NETWORK:
            return {
                ...state,
                getCountriesWhoHasNetworkList: [],
                getCountriesWhoHasNetworkLoading: true,
                getCountriesWhoHasNetworkError: null,
            };
        case actions.GET_COUNTRIES_WHO_HAS_NETWORK_SUCCESS:
            return {
                ...state,
                getCountriesWhoHasNetworkList: action.payload.data,
                getCountriesWhoHasNetworkLoading: false,
                getCountriesWhoHasNetworkError: null,
            };
        case actions.GET_COUNTRIES_WHO_HAS_NETWORK_FAILED:
            return {
                ...state,
                getCountriesWhoHasNetworkList: [],
                getCountriesWhoHasNetworkLoading: false,
                getCountriesWhoHasNetworkError: action.payload.error,
            };

        case actions.GET_SERVICES:
            return {
                ...state,
                serviceLoading: true,
                serviceError: null,
                serviceMessage: null,
                serviceList:[]
            };
        case actions.GET_SERVICES_SUCCESS:
            return {
                ...state,
                serviceList: action.payload.data,
                serviceLoading: false,
                serviceError: null,
                serviceMessage: null,
            };
        case actions.GET_SERVICES_FAILED:
            return {
                ...state,
                serviceLoading: false,
                serviceError: action.payload.error,
                serviceMessage: null,
            };
        default:
            return state;
    }
}